import { lazy, useEffect } from 'react';
import { v4 as uuidv } from 'uuid';
import { useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { appRoutes } from './routes/config';

import { useScrollToTop } from './hooks/use-scroll-to-top';

import HomeLayout from './layouts/home';
import DashboardLayout from './layouts/dashboard';

import ProtectedRoute from './routes/ProtectedRoute';
import UnProtectedRoute from './routes/UnProtectedRoute';

// get me
import { googleLoginSuccess } from './store/authSlice';
const RegisterPage = lazy(() => import('./pages/Register'));
const LoginPage = lazy(() => import('./pages/login'));
const Page404 = lazy(() => import('./pages/page-not-found'));
// home
const Home = lazy(() => import('./layouts/home/Home'));
const Pricing = lazy(() => import('./layouts/home/Pricing'));
const Contact = lazy(() => import('./layouts/home/Contact'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsConditions = lazy(() => import('./pages/TermsConditions'));
const Profile = lazy(() => import('./pages/Profile'));

// --------------------------------------------------------

export default function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useScrollToTop();
  useEffect(() => {
    const queryString = window.location.search;

    // Extract the "user" parameter value and decode it
    const encodedUserData = new URLSearchParams(queryString).get('user');
    if (encodedUserData) {
      try {
        const decodedUserData = decodeURIComponent(encodedUserData);

        // Parse the JSON string into a JSON object
        const userData = JSON.parse(decodedUserData);

        // Dispatch the action with the parsed user data
        dispatch(googleLoginSuccess(userData))
          .then(() => {
            navigate('/app/dashboard');
          })
          .catch(err => {
            console.error('Login failed:', err);
          });
      } catch (error) {
        console.error('Failed to parse user data:', error);
      }
    }
  }, [dispatch, navigate]);

  const { authInfo } = useSelector(state => state.auth);

  return (
    <Routes>
      <Route path="/" element={<HomeLayout />}>
        <Route index element={<Home />} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="contact" element={<Contact />} />
        <Route path="policy" element={<PrivacyPolicy />} />
        <Route path="terms" element={<TermsConditions />} />
      </Route>
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/register"
        element={
          <UnProtectedRoute>
            <RegisterPage />
          </UnProtectedRoute>
        }
      />

      <Route
        path="/app/"
        element={
          <ProtectedRoute>
            <DashboardLayout />
          </ProtectedRoute>
        }
      >
        {authInfo !== null
          ? appRoutes?.map(route => (
              <Route
                key={uuidv()}
                path={route.path}
                element={<ProtectedRoute>{route.element}</ProtectedRoute>}
              />
            ))
          : null}
        <Route path="accounts/:tabTitle" element={<Profile />} />
      </Route>
      <Route
        path="*"
        element={
          <UnProtectedRoute>
            <Page404 />
          </UnProtectedRoute>
        }
      />
    </Routes>
  );
}
