import { useMemo, useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';
import { palette } from './palette';
import { shadows } from './shadows';
import { overrides } from './overrides';
import { typography } from './typography';
import { customShadows } from './custom-shadows';

// ----------------------------------------------------------------------

const ThemeModeContext = createContext();

export function useThemeMode() {
  return useContext(ThemeModeContext);
}

export default function ThemeProvider({ children }) {
  const [mode, setMode] = useState('light');

  const themeMode = useMemo(
    () => ({
      mode,
      toggleMode: () => {
        setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [mode],
  );

  const memoizedValue = useMemo(
    () => ({
      palette: palette(mode),
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
      shape: { borderRadius: 8 },
    }),
    [mode],
  );

  const theme = createTheme(memoizedValue);
  theme.components = overrides(theme);

  return (
    <ThemeModeContext.Provider value={themeMode}>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </ThemeModeContext.Provider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
};
