import { Suspense } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { GoogleOAuthProvider } from '@react-oauth/google';

import './index.css';
import ThemeProvider from './theme';

import App from './app';
import store from './store/redux';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <ThemeProvider>
      <Provider store={store}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <Suspense>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Suspense>
        </GoogleOAuthProvider>
      </Provider>
    </ThemeProvider>
  </HelmetProvider>,
);
