import React from 'react';
import { IconButton } from '@mui/material';
import { WbSunnyRounded, ModeNightRounded } from '@mui/icons-material';
import { useThemeMode } from '../theme';

function ThemeToggleButton() {
  const { mode, toggleMode } = useThemeMode();
  return (
    <IconButton onClick={toggleMode}>
      {mode === 'dark' ? (
        <WbSunnyRounded fontSize="small" />
      ) : (
        <ModeNightRounded fontSize="small" />
      )}
    </IconButton>
  );
}

export default ThemeToggleButton;
