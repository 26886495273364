import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import RestApi from '../api/RestApi';

// Async Thunk for testing connection
export const testConnection = createAsyncThunk(
  'emailAccount/testConnection',
  async (emailAccountData, { getState, rejectWithValue }) => {
    try {
      const { authInfo } = getState().auth;
      const response = await RestApi.post(
        '/appv1/test-smtp-connections',
        emailAccountData,
        {
          headers: {
            Authorization: `Bearer ${authInfo.token}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  },
);

// Async Thunk for creating an email account
export const createEmailAccount = createAsyncThunk(
  'emailAccount/createEmailAccount',
  async (emailAccountData, { getState, rejectWithValue }) => {
    try {
      const { authInfo } = getState().auth;
      const response = await RestApi.post(
        '/appv1/create-email-account',
        emailAccountData,
        {
          headers: {
            Authorization: `Bearer ${authInfo.token}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  },
);

// Async Thunk for updating an email account
export const updateEmailAccount = createAsyncThunk(
  'emailAccount/updateEmailAccount',
  async ({ id, emailAccountData }, { getState, rejectWithValue }) => {
    try {
      const { authInfo } = getState().auth;
      const response = await RestApi.put(
        `/appv1/update-email-account/${id}`,
        emailAccountData,
        {
          headers: {
            Authorization: `Bearer ${authInfo.token}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  },
);

// Async Thunk for deleting an email account
export const listEmailAccount = createAsyncThunk(
  'emailAccount/listEmailAccount',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { authInfo } = getState().auth;
      const response = await RestApi.get('/appv1/list-email-account', {
        headers: {
          Authorization: `Bearer ${authInfo.token}`,
        },
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  },
);

const emailAccountSlice = createSlice({
  name: 'emailAccount',
  initialState: {
    list: null,
    status: 'idle',
    error: null,
    success: null,
    isConnectionSuccessful: false,
  },
  reducers: {
    clearMessages: state => {
      state.success = null;
      state.error = null;
      state.isConnectionSuccessful = false;
    },
  },
  extraReducers: builder => {
    builder
      // Test Connection
      .addCase(testConnection.pending, state => {
        state.status = 'loading';
      })
      .addCase(testConnection.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.success = action.payload.message;
        state.isConnectionSuccessful = true;
        // Handle success if needed
      })
      .addCase(testConnection.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        state.isConnectionSuccessful = false;
      })

      // Create Email Account
      .addCase(createEmailAccount.pending, state => {
        state.status = 'loading';
      })
      .addCase(createEmailAccount.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.success = action.payload.message;
      })
      .addCase(createEmailAccount.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Update Email Account
      .addCase(updateEmailAccount.pending, state => {
        state.status = 'loading';
      })
      .addCase(updateEmailAccount.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Update state with updated email account if needed
      })
      .addCase(updateEmailAccount.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Delete Email Account
      .addCase(listEmailAccount.pending, state => {
        state.status = 'loading';
      })
      .addCase(listEmailAccount.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.list = action.payload;
      })
      .addCase(listEmailAccount.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { clearMessages } = emailAccountSlice.actions;

export default emailAccountSlice.reducer;
