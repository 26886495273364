// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import RestApi from '../api/RestApi';
// Async thunk for user login
export const userRegister = createAsyncThunk(
  'auth/userRegister',
  async (formData, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      // Make request to the backend
      const { data } = await RestApi.post('/create-user', formData, config);

      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  },
);
// Async thunk for user login
export const userLogin = createAsyncThunk(
  'auth/userLogin',
  async (formData, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      // Make request to the backend
      const { data } = await RestApi.post('/auth/user-login', formData, config);

      // Store user's token in local storage
      sessionStorage.setItem('authInfo', JSON.stringify(data?.userDetails));

      return data?.userDetails;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  },
);
export const googleLoginSuccess = createAsyncThunk(
  'auth/googleLoginSuccess',
  async (userData, { rejectWithValue }) => {
    try {
      // Store user's token in session storage
      sessionStorage.setItem('authInfo', JSON.stringify(userData));
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  },
);
// Auth slice with initial state
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    authInfo: sessionStorage.getItem('authInfo')
      ? JSON.parse(sessionStorage.getItem('authInfo'))
      : null,
    error: null,
    success: false,
  },
  reducers: {
    clearError: state => {
      state.success = null;
      state.error = null;
    },
    logout: state => {
      sessionStorage.removeItem('authInfo'); // Deletes token from storage
      state.loading = false;
      state.authInfo = null;
      state.error = null;
      state.success = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(userLogin.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.authInfo = payload;
        state.success = true;
      })
      .addCase(userLogin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(userRegister.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(userRegister.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.authInfo = payload;
        state.success = true;
      })
      .addCase(userRegister.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

// Export actions
export const { clearError, logout } = authSlice.actions;
export default authSlice.reducer;
